<template>
  <el-popover 
    placement='bottom' 
    :width='widthCss' 
    v-model='showPopover' 
    @show='getNeedMyApprovalTotal'
    trigger='click'>
    <span 
      slot='reference'
      class='alert-icon'>
      <el-badge 
        :value='approvalResponsesCount'
        :hidden='!hasApprovalResponses'
        class='item'>
        <bell-icon 
          size='1.25x' 
          stroke-width='2' 
          class='cursor-pointer'
          :class='hasApprovalResponsesCss' />
      </el-badge>
    </span>
    <div class='p-4'>
      <h1 class='text-xl font-semibold mb-4'>승인 요청</h1>
      <needs-your-approval v-if='hasApprovalResponses'
        :approval-responses='approvalResponses' />
      <div v-else>승인 요청 건이 없습니다.</div>
    </div>
  </el-popover>
</template>

<script>
import NeedsYourApproval        from '@/components/approvals/NeedsYourApproval.vue'
import { mapActions, mapState } from 'vuex'
import { BellIcon }             from 'vue-feather-icons'

export default {
  name: 'ApprovalDialog',
  components: {
    NeedsYourApproval,
    BellIcon,
  },
  data () {
    return {
      showPopover: false,
    }
  },
  methods: {
    ...mapActions('approvals', [
      'getNeedMyApprovalTotal',
    ]),
  },
  computed: {
    ...mapState('approvals', [
      'approvalResponses',
    ]),
    hasApprovalResponsesCss () {
      return (this.hasApprovalResponses) ? 'text-red-700 opacity-75' : 'text-gray-400 opacity-50'
    },
    approvalResponsesCount () {
      return (this.approvalResponses) ? this.approvalResponses.length : 0
    },
    hasApprovalResponses () {
      return this.approvalResponsesCount > 0
    },
    widthCss () {
      return this.hasApprovalResponses ? '800' : '200'
    }
  },
  mounted () {
    this.getNeedMyApprovalTotal()
  }
}
</script>

<style lang='scss' scoped>
.alert-icon {
  padding: 0.5rem;
  width: 1.25rerm;
  height: 1.25rerm;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
}

.alert-icon:hover {
  opacity: 1.0;
}
</style>
